"use client"; // Ensure this is at the top for client-side rendering
import React, { useState, useEffect } from "react";
import demoFields from "./fields";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";
import GoogleCaptchaWrapper from "../../../../../components/GoogleCaptchaWrapper/GoogleCaptchaWrapper";

const Form = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        phone_number: "",
        full_name: "",
        registration_id: "",
        issueType: false,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const sanitizeInput = (input) => {
        const sanitizedText = input.replace(/[^\p{L}\p{N}\s@.,'"-]/gu, "");

        return sanitizedText;
    };
    const sanitizephonenumber = (phonenumberToBeSanitized) => {
        let formattedValue = phonenumberToBeSanitized.replace(/\D/g, "");
        return formattedValue;
    };
    const handleChange = (e) => {
        const value =
            e.target.type === "checkbox" ? e.target.checked : e.target.value;

        if (e.target.name === "registration_id") {
            if (value.length > 29) return;
            setFormData({
                ...formData,
                [e.target.name]: sanitizephonenumber(value),
            });
            return;
        }
        if (e.target.name === "phone_number") {
            setFormData({
                ...formData,
                [e.target.name]: sanitizephonenumber(value),
            });
            return;
        }

        if (e.target.type === "text") {
            setFormData({
                ...formData,
                [e.target.name]: sanitizeInput(value),
            });
            return;
        }
        setFormData({ ...formData, [e.target.name]: value });
    };

    const handleCheckRid = () => {
        let RidPassed = true;
        for (const key in formData) {
            if (key === "registration_id") {
                if (!/^\d{29}$/.test(formData[key]) || formData[key] === "") {
                    alert(
                        "Please enter a 29-digit numeric character for registration ID."
                    );
                    setButtonLoading(false);
                    RidPassed = false;
                    return;
                }
            }
        }
        const isRepeated = /^(\d)\1*$/.test(formData.registration_id);
        if (isRepeated) {
            RidPassed = false;
            alert("Please insert a correct registration Id");
        }
        return RidPassed;
    };

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmit = async (e) => {
        e.preventDefault();

        setButtonLoading(true);
        if (formData.registration_id !== "" && !handleCheckRid()) {
            return;
        }
        if (!executeRecaptcha) {
            return;
        }

        const gRecaptchaToken = await executeRecaptcha(
            "helpformrecaptchaauthentication"
        );

        fetch(`${process.env.REACT_APP_BASE_URL}/submit_form/`, {
            redirect: "follow",
            method: "POST",
            body: JSON.stringify({
                full_name: formData?.full_name,
                phone_number: formData?.phone_number,
                rid: !formData.issueType ? formData.registration_id : "",
                captcha_token: gRecaptchaToken,
            }),
            headers: {
                "content-type": "text/plain;charset=utf-8",
            },
        })
            .then(async (response) => {
                const data = await response.json();
                console.log(data);
                if (response.ok) {
                    setButtonLoading(false);
                  
                    alert(t(data.success))
                   
                    navigate("/", { replace: true });
                } else {
                    setButtonLoading(false);
                    console.error("Error adding data to sheet.");
                    alert(t(data.error))
                }
            })
            .catch((error) => {
                setButtonLoading(false);
                console.error(error);
            });


        setFormData({});
    };

    const renderFormField = (field) => {
        if (field.parent && formData.issueType !== field.parent) {
            return null;
        }

        switch (field.type) {
            case "text":
                return (
                    <input
                        key={field.name}
                        className='border-2 w-full max-w-full rounded-sm opacity-25 focus:opacity-100 px-2 py-0.5'
                        type='text'
                        name={field.name}
                        placeholder={field.placeholder}
                        required={field.mandatory}
                        value={formData[field.name] || ""}
                        onChange={handleChange}
                        readOnly={field.readOnly}
                        disabled={buttonLoading}
                        autoComplete='off'
                    />
                );
            case "phonenumber":
                return (
                    <input
                        key={field.name}
                        className='border-2 w-full max-w-full rounded-sm opacity-25 focus:opacity-100 px-2 py-0.5'
                        type='phonenumber'
                        name={field.name}
                        placeholder={field.placeholder}
                        required={field.mandatory}
                        value={formData[field.name] || ""}
                        onChange={handleChange}
                        readOnly={field.readOnly}
                        disabled={buttonLoading}
                        autoComplete='off'
                        minLength={field.name === "registration_id" ? 29 : 10}
                        maxLength={field.name === "registration_id" ? 29 : 10}
                    />
                );
            // case "checkbox":
            //   return (
            //     <input
            //       type='checkbox'
            //       name={field.name}
            //       required={field.mandatory}
            //       checked={formData[field.name] || false}
            //       onChange={handleChange}
            //       disabled={buttonLoading}
            //     />
            //   );
            case "checkbox":
                return (
                    <div
                        className='flex items-center space-x-2'
                        key={field.name}
                    >
                        <input
                            type='checkbox'
                            name={field.name}
                            required={field.mandatory}
                            // checked={formData[field.name] === field.name}
                            onChange={handleChange}
                            disabled={buttonLoading}
                        />
                        <label className='text-MetallicBlue font-semibold'>
                            {t(field.label)}
                        </label>
                    </div>
                );
            case "select":
                return (
                    <select
                        key={field.name}
                        name={field.name}
                        value={formData[field.name] || ""}
                        onChange={handleChange}
                        disabled={buttonLoading}
                        className='w-full max-w-full'
                    >
                        {field.options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        if (emailSent) {
        }
        setEmailSent(false);
    }, [emailSent]);

    return (
        <div className='w-full px-4 lg:px-8'>
            {message && <div className='text-center mb-4'>{message}</div>}
            <form
                className='w-full max-w-[800px] min-w-fit mx-auto'
                onSubmit={handleSubmit}
            >
                <div className='flex flex-col lg:flex-row text-black/50 border-white/20 text-start p-4 lg:p-8 rounded-lg'>
                    <div className='flex flex-col w-full gap-4'>
                        {demoFields.map((field, index) => (
                            <div
                                className='flex flex-col w-full gap-2'
                                key={index}
                            >
                                <div className='flex flex-col w-full'>
                                    <h3 className='text-lg font-semibold'>
                                        {index !== 3 && t(field.label)}
                                    </h3>
                                    {renderFormField(field)}
                                </div>
                            </div>
                        ))}
                        <div className='flex flex-col md:flex-row gap-4 md:gap-8 justify-center'>
                            <button
                                type='submit'
                                disabled={buttonLoading}
                                className={`border rounded-lg py-2 px-7 ${
                                    buttonLoading
                                        ? "bg-[darkgray]"
                                        : "bg-green text-white hover:bg-MetallicBlue"
                                } transition-all duration-200 text-lg whitespace-pre-line`}
                            >
                                {buttonLoading
                                    ? "loading..."
                                    : t("help-submit")}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Form;
