
export const benefitsData = {
    title: "benefits-title",
    description: "benefits-description",
    content: [
        {
            title: "benefits-section-1-title",
            listContent: [
                "benefits-section-1-body-1",
                "benefits-section-1-body-2",
                "benefits-section-1-body-3",
                "benefits-section-1-body-4",
                "benefits-section-1-body-5",
            ],
        },
        {
            title: "benefits-section-2-title",
            listContent: [
                "benefits-section-2-body-1",
                "benefits-section-2-body-2",
                "benefits-section-2-body-3",
                "benefits-section-2-body-4",
                "benefits-section-2-body-5",
                "benefits-section-2-body-6",
                "benefits-section-2-body-7",
                "benefits-section-2-body-8",
                "benefits-section-2-body-9",
                "benefits-section-2-body-10",
                "benefits-section-2-body-11",
                "benefits-section-2-body-12",
                "benefits-section-2-body-13",

            ],
        },
        {
            title: "benefits-section-3-title",
            listContent: [
                "benefits-section-3-body-1",
                "benefits-section-3-body-2",
                "benefits-section-3-body-3",
                "benefits-section-3-body-4",
                "benefits-section-3-body-5",
                "benefits-section-3-body-6",
                "benefits-section-3-body-7",
                "benefits-section-3-body-8",
                "benefits-section-3-body-9",
                "benefits-section-3-body-10",
                "benefits-section-3-body-11",
            ],
        },
        {
            title: "benefits-section-4-title",
            listContent: [
                "benefits-section-4-body-1",
                "benefits-section-4-body-2",
                "benefits-section-4-body-3",
            ],
        },
        {
            title: "benefits-section-5-title",
            listContent: [
                "benefits-section-5-body-1",
                "benefits-section-5-body-2",
            ],
        },
    ],
};