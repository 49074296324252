import React from "react";
import { useTranslation } from "react-i18next";
import mainLogo from "../../../../assets/check-mark.png";
import diagram from "../../../../assets/diagram.png";
import {benefitsData} from './benefitsContentData'
const Body = () => {
    const { t } = useTranslation();
    
    return (
        <div className='min-h-[90vh] text-MetallicBlue py-10 text-pretty text-justify text-pretty'>
            <div className='px-5 grid lg:grid-cols-2 w-full text-justify md:px-28 bottom-10 text-MetallicBlue gap-x-5 gap-y-3'>
                <div className='space-y-3 lg:pt-10'>
                    <h1 className='text-4xl md:text-5xl font-extrabold'>
                        {t(benefitsData.title)}
                    </h1>
                    <p className='text-xl md:text-3xl indent-5'>
                        {t(benefitsData.description)}
                    </p>
                </div>
                <div className='w-full flex justify-center'>
                    <img
                        src={diagram}
                        alt='diagram'
                        className='w-full md:min-w-[400px] max-w-[500px] md:max-w-[700px]'
                    />
                </div>
            </div>
            <div>
                {benefitsData.content.map((section, index) => (
                    <div
                        key={index}
                        className='my-10 space-y-2 md:px-32 bg-[#F2F2F2] py-10 px-5'
                    >
                        <div className='flex items-center gap-1'>
                            <img src={mainLogo} className='h-[25px] w-[25px]' />
                            <h2 className='text-xl md:text-3xl text-left md:text-justify font-bold'>
                                {t(section.title)}
                            </h2>
                        </div>
                        <ul className='px-5 md:px-16 text-lg md:text-xl space-y-1 ps-10'>
                            {section.listContent.map((item, index) => (
                                <li className='list-disc' key={index}>
                                    {t(item)}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Body;
