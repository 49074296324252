import React, { useState, useEffect, lazy, useContext } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../components/footer/footer";
import News from "../landing/components/news/news";

import About from "../landing/components/about/About";
import AboutStakeholder from "../landing/components/about/AboutStakeholder";
import { useMediaQuery } from "react-responsive";
import NewsMobile from "../landing/components/news/newsMobile";
import Testimonials from "../landing/components/testimonials/testimonials";
import Principle from "../landing/components/principles/principle";
import HeroLandingPage from "../landing/components/hero/heroLandingPage";

import OverlayedPrinciple from "../landing/components/principles/OverlayedPrinciple";
import PrincipleMobile from "../landing/components/principles/principleMobile";
import Faq from "./components/faq/faq";

import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import {
    getAbout,
    getFaq,
    getTest,
    getTopNews,
    getNews,
} from "../../redux/selectors";
import {
    getNewsList,
    getFaqList,
    getTestList,
    getGalleryList,
} from "../../redux/actions";

import { ViewContext } from "../../providers/viewContext";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Podcast from "./components/podcast/podcast";
import Newpage from "./components/newpage/newpage";
import { useTranslation } from "react-i18next";
import IDHeroPodcast from "./components/podcast/idDayPodcast";

function Landing() {
    const dispatch = useDispatch();
    const { view, setView } = useContext(ViewContext);
    const [latest, setLatest] = useState([]);

    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    useEffect(() => {
        if (location.pathname === "/contact") {
            // Scroll to the bottom of the page when the route matches "/contact"
            window.scrollTo(0, document.body.scrollHeight);
        }
    }, [location]);

    useEffect(() => {
        // const timer = setTimeout(() => {
        //   setIsLoading(false);
        // }, 10000);

        // Group the dispatch calls based on their functionality
        const dataDispatches = [
            dispatch(getNewsList()),
            dispatch(getTestList()),
            dispatch(getFaqList()),
        ];

        // Execute the dispatch calls in parallel
        Promise.all(dataDispatches)
            .then(() => {
                setIsLoading(false);
                // Do something after all the dispatch calls have completed
            })
            .catch((error) => {
                // Handle any errors that occur during the dispatch calls
            });

        // return () => {
        //   clearTimeout(timer);
        // };
    }, [dispatch]);

    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <HeroLandingPage citizen={view} />

                    <div className='overflow-hidden'>
                        {isDesktopOrLaptop ? (
                            <>
                                {view === "Citizen" ? (
                                    <>
                                        <About /> <Principle />{" "}
                                    </>
                                ) : (
                                    <>
                                        {" "}
                                        <AboutStakeholder /> <Principle />
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <About />
                                <PrincipleMobile />
                            </>
                        )}

                        {isDesktopOrLaptop ? <News /> : <NewsMobile />}
                        <Podcast />
                        {/* <IDHeroPodcast/> */}
                        <Testimonials />
                        <Faq />
                    </div>
                    <Footer />
                </>
            )}
        </>
    );
}

export default connect()(Landing);
